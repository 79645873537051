// src/components/Seo.tsx
import React from 'react';
import { Helmet } from 'react-helmet';
import { WebsiteConfig } from '../website-config';
import { colors } from '../styles/colors';

interface PostFrontmatter {
  title: string;
  excerpt?: string;
  date?: string;
  tags?: string[];
  image?: any;
  author?: Array<{ name: string }>;
}

interface Post {
  frontmatter: PostFrontmatter;
  excerpt?: string;
}

interface ImageDimensions {
  width?: number;
  height?: number;
}

export interface SeoProps {
  config: WebsiteConfig;
  post?: Post | undefined;
  location?:
    | {
        pathname: string;
      }
    | undefined;
  imageDimensions?: ImageDimensions;
  title?: string;
  description?: string;
  image?: any;
}
/**
 * SEO Component for optimizing page metadata
 *
 * This component handles various metadata tags for:
 * 1. Basic SEO
 *    - Page title
 *    - Meta description
 *    - Language
 *
 * 2. Open Graph Protocol (Facebook, LinkedIn)
 *    - Title, description, image for social media sharing
 *    - Content type (article vs website)
 *    - URL and site name
 *    - Image dimensions for optimal display
 *
 * 3. Article-Specific Metadata
 *    - Publication date
 *    - Tags/Categories
 *    - Author information
 *
 * 4. Twitter Cards
 *    - Large summary card with image
 *    - Title, description, image
 *    - Author and category labels
 *    - Twitter handles for site and creator
 *
 * 5. Search Engine Optimization
 *    - Canonical URL to prevent duplicate content
 *    - Google site verification
 *
 * Usage:
 * <Seo
 *   config={siteConfig}
 *   post={postData}              // Optional: for blog posts
 *   location={location}          // For URL construction
 *   imageDimensions={{          // Optional: for OG image
 *     width: 1200,
 *     height: 630
 *   }}
 * />
 */
export function Seo({
  config,
  post,
  location,
  imageDimensions,
  title: defaultTitle,
  description: defaultDescription,
  image: defaultImage,
}: SeoProps): JSX.Element {
  const isBlogPost = Boolean(post?.frontmatter);

  const title = post?.frontmatter?.title ?? defaultTitle ?? config.title;
  const description =
    post?.frontmatter?.excerpt ?? post?.excerpt ?? defaultDescription;
  const url = location?.pathname
    ? `${config.siteUrl}${location.pathname}`
    : config.siteUrl;

  const getSocialHandle = (url: string): string | undefined => {
    const parts = url.split('/');
    const handle = parts[parts.length - 1];
    return handle || undefined;
  };

  return (
    <Helmet>
      <html lang={config.lang} />
      <title>{title}</title>
      <meta name="description" content={config.description} />

      {/* OpenGraph */}
      <meta property="og:site_name" content={config.title} />
      <meta property="og:type" content={isBlogPost ? 'article' : 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={defaultImage} />

      {imageDimensions?.width && (
        <meta
          property="og:image:width"
          content={imageDimensions.width.toString()}
        />
      )}
      {imageDimensions?.height && (
        <meta
          property="og:image:height"
          content={imageDimensions.height.toString()}
        />
      )}

      {/* Article specific metadata */}
      {isBlogPost && post?.frontmatter?.date && (
        <meta
          property="article:published_time"
          content={post.frontmatter.date}
        />
      )}
      {isBlogPost && post?.frontmatter?.tags?.[0] && (
        <meta property="article:tag" content={post.frontmatter.tags[0]} />
      )}

      {/* Publisher/Author */}
      {config.linkedin && (
        <meta property="article:publisher" content={config.linkedin} />
      )}
      {config.linkedin && (
        <meta property="article:author" content={config.linkedin} />
      )}
      <meta name="author" content="Iury Souza" />
      <meta name="keywords" content={post?.frontmatter?.tags?.join(', ')} />
      <meta name="theme-color" content={colors.logoBg} />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={defaultImage} />

      {/* Twitter Author/Tags */}
      {isBlogPost && post?.frontmatter?.author?.[0]?.name && (
        <meta name="twitter:label1" content="Written by" />
      )}
      {isBlogPost && post?.frontmatter?.author?.[0]?.name && (
        <meta name="twitter:data1" content={post.frontmatter.author[0].name} />
      )}
      {isBlogPost && post?.frontmatter?.tags?.[0] && (
        <meta name="twitter:label2" content="Filed under" />
      )}
      {isBlogPost && post?.frontmatter?.tags?.[0] && (
        <meta name="twitter:data2" content={post.frontmatter.tags[0]} />
      )}

      {/* Twitter Site/Creator */}
      {config.twitter && (
        <meta
          name="twitter:site"
          content={`@${getSocialHandle(config.twitter)}`}
        />
      )}
      {config.twitter && (
        <meta
          name="twitter:creator"
          content={`@${getSocialHandle(config.twitter)}`}
        />
      )}

      {/* Google Verification */}
      {config.googleSiteVerification && (
        <meta
          name="google-site-verification"
          content={config.googleSiteVerification}
        />
      )}

      {/* Canonical URL */}
      <link rel="canonical" href={url} />
    </Helmet>
  );
}
